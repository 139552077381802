<template>
  <v-container>
    
    <v-row>
      <v-col cols="12">

        <v-row class="mb-3">
          <v-col>
            <v-btn to="/admin" color="secondary" small>
              <v-icon class="mr-2">mdi-arrow-left</v-icon>
              Volver
            </v-btn>
          </v-col>
        </v-row>

        <v-card>
          <v-card-title class="pb-0">

            <v-container>

              <v-row>
                <v-col class="mb-2 py-0">
                  <p class="text-h5 mb-0">
                    <v-avatar color="orange" size="38"><v-icon dark>mdi-newspaper</v-icon></v-avatar>
                    Gestión de Noticias
                  </p>
                </v-col>
              </v-row>

              <v-row class="mt-2 py-0">
                <v-col cols="8">
                  <v-text-field v-model="search" label="Buscar" prepend-inner-icon="mdi-magnify" single-line hide-details clearable filled rounded dense class="searchinput" />
                </v-col>
                <v-col cols="4">
                  <v-btn block small color="success" class="mt-1" to="/admin/news/createarticle" >
                    Nueva Noticia
                  </v-btn>
                </v-col>

              </v-row>

            </v-container>
            
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="news"
            :search="search"
            :loading="isLoading"
          >

            <template v-slot:item.isVisible="{ item }">
              <v-chip :color="(item.isVisible) ? 'green' : 'grey'" small dark >
                {{(item.isVisible) ? 'Visible' : 'Oculto'}}
              </v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="goToArticle(item.slug)">mdi-pencil</v-icon>
              <v-icon small class="mr-2" @click="openDeleteDialog(item._id)">mdi-close</v-icon>
            </template>

          </v-data-table>

        </v-card>

      </v-col>
    </v-row>

    <DeleteItemDialog v-model="deleteDialog" :deleteURL="deleteURL" @deleted="loadArticles()" />

  </v-container>
</template>

<script>

import DeleteItemDialog from "@/components/admin/DeleteItemDialog";

export default {
  name: "AdminNews",
  components: {
    DeleteItemDialog
  },
  data() {
    return {
      //state
      search: '',
      isLoading: true,
      deleteDialog: false,
      deleteURL: '',
      //definitions
      headers: [
        { text: 'Visibilidad', value: 'isVisible' },
        { text: 'Fecha', value: 'date' },
        { text: 'Título', value: 'title' },
        { text: 'Acciones', value: 'actions' },
      ],
      news: []
    }
  },
  methods: {
    async loadArticles() {
      try {

        this.isLoading = true

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/article/all`)

        this.news = response.data.data.map(article => {
          return {
            _id: article._id,
            slug: article.slug,
            title: article.title,
            isVisible: article.isVisible,
            date: new Date(article.date).toLocaleString(),
          }
        })

      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    goToArticle(slug) {
      this.$router.push(`/admin/news/editarticle/${slug}`)
    },
    openDeleteDialog(id) {
      this.deleteURL = `${process.env.VUE_APP_APIURL}/article/id/${id}`
      this.deleteDialog = true
    },
  },
  mounted() {
    this.loadArticles()
  }
}
</script>
